import axios from 'axios';
const Axios = axios.create({
  baseURL: 'https://tpl.baytulikhtiar.com/api/'
})
const authRegister = (payload) => {
  return Axios.post('auth/register', payload)
}
const authCheckPassword = (payload) => {
  return Axios.post('/auth/check_password', payload)
}
const authUsername = (payload) => {
  return Axios.post('auth/check_fa_code', payload)
}
const infoRembug = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/information/rembug', payload, config)
}
const infoMember = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/information/member', payload, config)
}
const transSetoranDeposit = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/transaction/deposit', payload, config)
}
const transSetoranDepositProses = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/transaction/process_deposit', payload, config)
}
const transPembiayaanPengajuan = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/transaction/submission', payload, config)
}
const transPembiayaanRab = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/transaction/rab', payload, config)
}
const transPembiayaanPencairan = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/transaction/droping', payload, config)
}
const transPembiayaanPencairanProses = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/transaction/process_droping', payload, config)
}
const registrasiCalonAnggota = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/registration/membership', payload, config)
}
const detilAnggotaKeluar = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.get('/registration/resign?'+payload, config)
}
const registrasiAnggotaKeluar = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/registration/process_resign', payload, config)
}
const productSaving = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/information/product_saving', payload, config)
}
const registrasiTaber = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/registration/open_saving', payload, config)
}
const pengajuanAnggota = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/transaction/list_submission_cif', payload, config)
}
const pengajuanRembug = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/transaction/list_submission_cm', payload, config)
}
const tabunganAnggota = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/information/saving', payload, config)
}
const pencairanTabungan = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/transaction/saving_withdrawal', payload, config)
}
const perpanjanganTabungan = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/information/saving_extension', payload, config)
}
const pengajuanDetail = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.get('/information/detail_submission?'+payload, config)
}
const updatePangajuanPembiayaan = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return Axios.post('/transaction/update_submission', payload, config)
}
export default {
  authRegister,
  authCheckPassword,
  authUsername,
  infoRembug,
  infoMember,
  transSetoranDeposit,
  transSetoranDepositProses,
  transPembiayaanPengajuan,
  transPembiayaanRab,
  transPembiayaanPencairan,
  transPembiayaanPencairanProses,
  registrasiCalonAnggota,
  detilAnggotaKeluar,
  registrasiAnggotaKeluar,
  productSaving,
  registrasiTaber,
  pengajuanAnggota,
  pengajuanRembug,
  tabunganAnggota,
  pencairanTabungan,
  perpanjanganTabungan,
  pengajuanDetail,
  updatePangajuanPembiayaan
}